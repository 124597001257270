import React, { useEffect } from "react";
import NavbarSpaceComponent from "components/global/navbarspace";
import FooterColComponent from "components/global/footerCol";
import startAnimation from "utils/animate";
import gsap from "gsap";
export default function ContactContainer() {
  useEffect(() => {
    startAnimation();
    gsap.to("body", {
      duration: 2,
      opacity: 1,
      delay: 1,
      ease: "power3.out",
    });
  }, []);

  return (
    <>
      <div className="wrapper contact-page ">
        <NavbarSpaceComponent reqDetail={false} />
        <main>
          <section className="section1">
            <div className="wrap">
              <img src="/assets/images/logo-gu-space.svg" alt="" />
              <h1>gu space</h1>
              <p style={{ fontSize: 16 }}>
                {/* INN-Office 466/9 Phahonyothin Road, สามเสนใน Phaya Thai, Bangkok
                10400 */}
                GU Space 22 Soi Samran Rat, Maha Chai Road, Phra Nakhon, Bangkok
                10200
              </p>
              <p>
                <a style={{ fontSize: 16 }} href="tel:080 945 6947">
                  080 945 6947
                </a>
              </p>
              <p>
                <a style={{ fontSize: 16 }} href="mailto:Contact@guspace.com">
                  contact@guspace.com
                </a>
              </p>
              <div className="social-wrap flex-row-cen-cen">
                <div className="item">
                  <a href="https://www.facebook.com/guspacebkk" target="_blank">
                    <img src="assets/images/i_facebook.svg" alt="" />
                  </a>
                </div>
                <div className="item">
                  <a href="https://www.tiktok.com/@guspacebkk " target="_blank">
                    <img src="assets/images/social/tiktok.svg" alt="" />
                  </a>
                </div>
                <div className="item">
                  <a
                    href="https://www.instagram.com/guspace_bkk"
                    target="_blank"
                  >
                    <img src="assets/images/social/ig_bw.svg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </section>

          <section className="joining joining_main bg-yellow py-12 md:py-10">
            <div className="grid text-center items-center">
              <div>
                <p>
                  หากคุณมีพื้นที่อยากเข้าร่วมกับ GU Space
                  <br />
                  สามารถส่งข้อมูลหาเราได้ที่นี่
                </p>
              </div>
              <div>
                <img src="assets/images/join.svg" alt="" className="mx-auto" />
                <h2 className="uppercase font-bold font-noto text-3xl mt-3">
                  Joining our
                  <br />
                  neighborhood
                </h2>
              </div>
              <div>
                <a
                  href="#!"
                  className="btn bg-black inline-block text-white rounded-full py-2.5 p-8 font-bold font-ibm text-base md:text-lg hover:underline"
                >
                  กรอกแบบฟอร์ม
                </a>
              </div>
            </div>
          </section>
        </main>
        <FooterColComponent />
      </div>
    </>
  );
}
