import React, { useEffect, useState } from 'react'
import useFunction from './function'
import CustomSelect from 'components/global/customSelect'
import DatePicker from 'components/global/datePicker'
// import navbarFunction from 'components/global/navbar/function'

export default function FilterComponent(props) {
  const { masterData } = useFunction()
  const { searchParams, onSelectLocation, onSelectEvent, onSelectPeople, onSelectDate, generateSearchParams, getParams, compareAndUpdateObjects, generateSearchParamsWithData} = useFunction()
  const [oldParams, setOldParams] = useState(null)
  function setValue(value ){ 
    return {name: value , value: value, disabled: 'ACTIVE' }
  }
  
  function onSearch() {
    if (props.updateMode) {
      let mergeParams = compareAndUpdateObjects(oldParams, searchParams )
      let params = generateSearchParamsWithData(mergeParams) 
      window.location.href = `/spaces${searchParams ? `?${params}` : ''}`
    }else{
      let params = generateSearchParams() 
      window.location.href = `/spaces${params ? `?${params}` : ''}`
    }

  }
  
  useEffect(() => {
    if (props.updateMode) {
      setOldParams(getParams())
    }
  }, [])

  return (
   
    <div> 
       <div className="group_search" style={{ opacity: 1 }}>
          <div className="custom-select dropdown w-50 input-location" style={{ backgroundColor: 'white' }}>
          
            <CustomSelect  all={true}
              options={
                masterData &&
                masterData.location &&
                masterData.location.map((item) => item.status === 'ACTIVE' && { name: item.name, value: item.name, disabled: item.status })
              }
              placeholder="Select Location"
              onSelect={onSelectLocation } 
              value={(props.updateMode && getParams().location) ? setValue(getParams().location) : '' }
            />
          </div>
          <div className="custom-select dropdown w-50 input-activity" style={{ backgroundColor: 'white', textTransform: 'capitalize' }}>
            <CustomSelect  all={true}
              options={
                masterData &&
                masterData.event &&
                masterData.event.map((item) => item.status === 'ACTIVE' && { name: item.name, value: item.name, disabled: item.status })
              }
              placeholder="Select Activity"
              onSelect={onSelectEvent}
              value={(props.updateMode && getParams().event) ? setValue(getParams().event) : '' }
            />
          </div>
          <div className="custom-select dropdown w-50 input-people" style={{ backgroundColor: 'white' }}>
            <CustomSelect  all={true}
             options={
              masterData &&
              masterData.people &&
              masterData.people.map(
                (item) =>
                  item.status === 'ACTIVE' && { name: `${item.number_of_people}`, value: item.number_of_people, disabled: item.status }
              )
            }
              placeholder="Number of People"
              onSelect={onSelectPeople}
              value={(props.updateMode && getParams().max_people) ? setValue(getParams().max_people) : '' }
            />
          </div>
          <div className="dropdown w-50 input-datepicker">
            {/* <input type="text" className="datepicker-input" datepicker="" datepicker-autohide="" placeholder="Select Date" /> */}
            <DatePicker placeholder={'Select Date'} onSelect={onSelectDate}  
            defaultValue={ (props.updateMode && getParams().selected_date) ?  getParams().selected_date : '' }  />
          </div>
          <button  className="btn-yellow w-full text-center justify-center mt-6" onClick={onSearch}>SEARCH</button>
          {/* <a
            href={`/spaces${searchParams ? `?${generateSearchParams()}` : ''}`}
            type="button"
           
          >
            SEARCH
          </a> */}
        </div>

    </div>
    )
}
