import React from 'react'
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,
  LineIcon
} from "react-share";
export default function FooterColComponent() {
  return (
    <footer class="footer_main">
          <div className="inner">
            <div className="top">
              <div className="logo_footer">
                <img src="assets/images/logo-gu-space.svg" alt="" />
              </div>
              <div className="social">
                <a href="#!">
                  <img src="assets/images/i_facebook.svg" alt="" />
                </a>
                <a href="#!">
                  <img src="assets/images/i_twitter.svg" alt="" />
                </a>
                <a href="#!">
                  <img src="assets/images/i_instagram.svg" alt="" />
                </a>
              </div>
            </div>
            <div className="copyright">
              <p>
                &copy; 2023 GU SPACE. Registered in Thailand. Company Registration Number 13324675. VAT Number 3795673330. Address: 18 Golden Road,
                Bangkok Thailand, 10900 Privacy Policy
              </p>
            </div>
          </div>
        </footer>
  )
}
