import React, { useEffect, useState, useContext } from 'react'
import CustomSelect from '../../global/customSelect'
// import TimePicker from '../../global/timePicker'
import DateRangPicker from 'components/global/dateRangPicker'
import DatePicker from '../../global/datePicker'
import LineIcon from 'assets/images/line-icon.png'
import DefaultImage from 'assets/images/default-image.jpg'
import useFuntion from './funtion'
import moment from 'moment/moment'
import PhoneInput from 'react-phone-input-2'
import ReCAPTCHA from 'react-google-recaptcha'
import { Modal } from 'antd'

import './style.css'
import 'react-phone-input-2/lib/style.css'
 
export default function Index({
  masterData,
  detailData,
  onChangePage,
  setResultStructure,
  freeSlots,
  setFreeSlots,
  onSelectDate,
  onSelectTimeRange,
  onChangeExpectArriavalTime,
  sumOrderPrice,
  sumOrderHours,
  sumOrderProcessPrice,
  findDuplicateSelected,
}) {
  const [isVisible, setIsVisible] = useState(false)
  const [listActives, setListActives] = useState(detailData) 
  // const { lineLoginData } = useContext(LineLogin)
  // const { id } = useParams()
  const {
    isRedirect,
    lineProfile,
    orderStructure,
    viaContract,
    onChangeRecaptcha,
    onUnlinkLine,
    loginLine,
    onChangeValue,
    onSelectContract,
    updateBooking,
    handleSubmitOrder,
  } = useFuntion(onChangePage, setResultStructure, freeSlots)

  useEffect(() => {
    const bookData = JSON.parse(localStorage.getItem('book_data'))
    if (bookData) {
      setFreeSlots(bookData)
    }
    let listActives = detailData
    listActives.event.push(
      {
        name: 'Other', 
      }) 

  }, [])

  useEffect(() => {
    window.scrollTo(0, 0)

    if (freeSlots.length > 0 && freeSlots[0].selected_range) {
      updateBooking(freeSlots, sumOrderPrice + sumOrderProcessPrice)
    }
  }, [freeSlots, sumOrderPrice, sumOrderProcessPrice])

  return (
    <div>
      <main className="booking">
        <div className="bg_brick03"></div>
        <div className="container_gu">
          <form action="">
            <div className="inner">
              <div className="col" style={{ padding: '0 0 20px 0' }}>
                <a href="#!" className="txthead">
                  <h2>Request to book</h2>
                </a>
                <div className="group border-0" style={{ padding: '20px 0px', marginTop: 20 }}>
                  <div className="title">About your event</div>
                  {/* <div > */}
                  {freeSlots.length > 0 &&
                    freeSlots.map((freeSlot) => {
                      return (
                        <div style={{ marginBottom: 10, gap: 10, display: 'flex', flexDirection: 'column' }} className="filter_date">
                          <div className="title-booking" style={{ marginTop: 20 }}>
                            <span className="req-mark">*</span>Date
                          </div>
                          <div className="gu_date">
                            <DatePicker
                              style={{ height: 48, borderRadius: 8 }}
                              onSelect={(e) => onSelectDate(e, freeSlot.index)}
                              placeholder="Select date"
                              defaultValue={freeSlot.selected_date}
                              value={freeSlot.selected_date}
                            />
                          </div>

                          <div className="title-booking">
                            <span className="req-mark">*</span>Hour
                          </div>
                          <div className="gu_time">
                            <DateRangPicker
                              style={{ height: 48, borderRadius: 8 }}
                              onSelect={(e) => onSelectTimeRange(e, freeSlot.index)}
                              options={
                                freeSlot && freeSlot.free_slots
                                  ? freeSlot.free_slots.map((slot) => {
                                      let isDuplicate = findDuplicateSelected(freeSlots, freeSlot, slot)
                                      return {
                                        value: {
                                          start: slot.start,
                                          end: slot.end,
                                          price_process: parseInt(slot.price_process),
                                        },
                                        name: `${slot.part} (${slot.start} - ${slot.end})`,
                                        disabled: isDuplicate ? isDuplicate : !slot.status,
                                      }
                                    })
                                  : [
                                      {
                                        value: 'none',
                                        name: `Something went wrong.`,
                                        disabled: true,
                                      },
                                    ]
                              }
                              placeholder="Select Hour"
                              value={freeSlot.selected_range}
                            />
                          </div>
                          <div
                            className="form_wrap"
                            style={{ padding: '15px 0px', gridTemplateColumns: 'unset', display: 'flex', flexDirection: 'column', gap: 20 }}
                          >
                            <label className="w-full">
                              Expected Arrival Time
                              <input
                                style={{ background: '#fff none' }}
                                type="text"
                                defaultValue={
                                  isRedirect
                                    ? orderStructure.booking_date &&
                                      orderStructure?.booking_date?.find(
                                        (bookData) =>
                                          moment(bookData.datetime_start).format('DD/MM/YYYY HH:ss') ===
                                          moment(`${freeSlot.selected_date} ${freeSlot.selected_range.value.start}`).format('DD/MM/YYYY HH:ss')
                                      )?.note
                                    : null
                                }
                                onChange={(e) => onChangeExpectArriavalTime(e.target.value, freeSlot.index)}
                              />
                            </label>
                          </div>
                        </div>
                      )
                    })}
                  {/* </div> */}
                </div>
                <div className="group" style={{ padding: '20px 0px' }}>
                  <div className="form_wrap" style={{ padding: '10px 0px' }}>
                    <label for="" className="w-full">
                      <span className="req-mark">*</span>Activity
                      <div className="custom-select dropdown">
                        <CustomSelect
                          options={
                            listActives &&
                            listActives.event &&
                            listActives.event.map((item) => item.name && { name: item.name, value: item.name, disabled: item.status })
                          }
                          defaultValue={isRedirect ? orderStructure.event : null}
                          onSelect={(e) => onChangeValue('event', e.value)}
                        /> 
                      </div>
                    </label> 
                    {orderStructure && orderStructure.event === 'Other' && (
                      <label className="w-full">
                      <span className="req-mark">*</span>Other Activity
                      <input
                        type="text" 
                        defaultValue={isRedirect ? orderStructure.event_other : null}
                        onChange={(e) => { 
                          onChangeValue('event_other', e.target.value) 
                        }}
                      />
                    </label>
                    )}

                    <label for="" className="w-full">
                      <span className="req-mark">*</span>How many people will attend ?
                      <div className="custom-select dropdown">
                        <CustomSelect
                          onSelect={(e) => onChangeValue('number_of_people', e.value)}
                          defaultValue={isRedirect ? orderStructure.number_of_people : null}
                          options={
                            masterData &&
                            masterData.people &&
                            masterData.people.map(
                              (item) =>
                                item.status === 'ACTIVE' && {
                                  name: `${item.number_of_people} People`,
                                  value: item.number_of_people,
                                  disabled: item.status,
                                }
                            )
                          }
                        />
                      </div>
                    </label>
                    <label for="" className="w-full">
                      <span className="req-mark">*</span>Will alcohol be consumed ?
                      <div className="custom-select dropdown">
                        <CustomSelect
                          defaultValue={isRedirect ? (orderStructure.alcohol ? 'Yes' : 'No') : null}
                          onSelect={(e) => onChangeValue('alcohol', e.value)}
                          options={['', { value: true, name: 'Yes', enabled: false }, { value: false, name: 'No', enabled: false }]}
                        />
                      </div>
                    </label>
                    <label for="" className="w-full">
                      Other Requirements
                      <textarea
                        defaultValue={isRedirect ? orderStructure.note : null}
                        onChange={(e) => onChangeValue('note', e.target.value)}
                        type="text"
                        style={{ height: 180 }}
                        placeholder="Your message"
                      />
                    </label>
                  </div>
                </div>
                <div className="group" style={{ padding: '25px 0px' }}>
                  <div className="title">About you</div>
                  <div
                    className="form_wrap"
                    style={{ padding: '15px 0px', gridTemplateColumns: 'unset', display: 'flex', flexDirection: 'column', gap: 20 }}
                  >
                    <label className="w-full">
                      <span className="req-mark">*</span>Full name
                      <input
                        type="text"
                        defaultValue={isRedirect ? orderStructure.full_name : null}
                        onChange={(e) => onChangeValue('full_name', e.target.value)}
                      />
                    </label>
                    <label className="w-full">
                      <span className="req-mark">*</span>Email
                      <input
                        type="email"
                        defaultValue={isRedirect ? orderStructure.email : null}
                        onChange={(e) => onChangeValue('email', e.target.value)}
                      />
                    </label>
                    <label className="w-full">
                      <span className="req-mark">*</span>Phone
                      {/* <input type="tel" onChange={(e) => onChangeValue('phone', e.target.value)} /> */}
                      <PhoneInput
                        value={isRedirect && orderStructure?.phone ? orderStructure?.phone : null}
                        country={'th'}
                        onChange={(phone) => onChangeValue('phone', phone)}
                      />
                    </label>
                    <label className="w-full">
                      <span className="req-mark">*</span>Prefer to contact via
                      <div className="custom-select dropdown">
                        <CustomSelect
                          defaultValue={isRedirect ? orderStructure.channel_contact : null}
                          defaultSelectFunction={() => (isRedirect ? onSelectContract({ value: orderStructure.channel_contact }) : () => {})}
                          onSelect={onSelectContract}
                          options={['', { value: 'line', name: 'Line', enabled: true }, { value: 'email', name: 'Email', enabled: true }]}
                        />
                      </div>
                    </label>
                    {viaContract === 'line' && !lineProfile ? (
                      <button
                        type="button"
                        onClick={() => {
                          loginLine()
                        }}
                        style={{
                          background: '#06C755',
                          borderRadius: 10,
                          height: 55,
                          margin: 0,
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                        className="text-center btn_booking rounded-full text-xl text-white  font-semibold p-4 w-full text-center items-center justify-center"
                      >
                        <img src={LineIcon} alt="line" style={{ width: 30, position: 'absolute', left: 20 }} />
                        <span>Login with Line</span>
                        <div />
                      </button>
                    ) : (
                      viaContract === 'line' && (
                        <div style={{ width: '100%' }}>
                          <div style={{ margin: '0 0 10px 0', fontWeight: 'bold', fontSize: 20, fontFamily: 'var(--fontNoto)' }}>
                            You Linked line account
                          </div>
                          <div
                            className="line-login-container"
                            style={{ display: 'flex', gap: 10, alignItems: 'center', width: '100%', justifyContent: 'space-between' }}
                          >
                            <div style={{ display: 'flex', gap: 10, alignItems: 'center', width: '100%' }}>
                              <img src={lineProfile.pictureUrl} alt="profileImage" style={{ width: 65, borderRadius: '50%' }} />
                              <div style={{ width: '100%', fontWeight: 'bold', fontSize: 16 }}>{lineProfile.displayName}</div>
                            </div>
                            <div className="unlink-line" onClick={() => onUnlinkLine()}>
                              <img src={LineIcon} alt="line" style={{ width: 25 }} />
                              <span>Unlink</span>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="booking_result">
                  <div className="bk_result_inner">
                    <div className="bk_result_top">
                      <div className="img">
                        <img src={(detailData && detailData.images && detailData.images[0]) || DefaultImage} alt="" />
                      </div>
                      <div className="txt">
                        <h2>{detailData && detailData.space_detail_options}</h2>
                        <p>{detailData && detailData.location}</p>
                      </div>
                    </div>
                    <div className="bk_result_date">
                      <h2>Date & Time</h2>
                      {freeSlots.map((slot) => {
                        return (
                          <p style={{ display: 'grid', gridTemplateColumns: '45% auto' }}>
                            <span>{moment(slot.selected_date).format('MMMM DD, YYYY')}</span>
                            <span>
                              {slot.selected_range && moment(slot.selected_range.value.start, 'HH:mm').format('HH:mm')} -
                              {slot.selected_range && moment(slot.selected_range.value.end, 'HH:mm').format('HH:mm')} hrs.
                            </span>
                          </p>
                        )
                      })}
                    </div>
                    <div className="bk_result_promocode">
                      <h2>Promo code</h2>
                      <input type="text" placeholder="Promo code" />
                    </div>
                    <div className="bk_result_price">
                      <h2>Price</h2>
                      <div className="flex items-center justify-between">
                        <p>{sumOrderHours} hours</p>
                        <p>{new Intl.NumberFormat().format(sumOrderPrice)} บาท</p>
                      </div>
                      <div className="flex items-center justify-between">
                        <p>Discount</p>
                        <p>{new Intl.NumberFormat().format(sumOrderProcessPrice)} บาท</p>
                      </div>
                    </div>
                  </div>
                  <div className="bk_result_total">
                    <div className="flex items-center justify-between">
                      <p>Total</p>
                      <h6>{new Intl.NumberFormat().format(sumOrderPrice + sumOrderProcessPrice)} บาท</h6>
                    </div>
                  </div>
                  <label className="bk_result_accept">
                    <input
                      type="checkbox"
                      checked={isRedirect ? orderStructure.policy : null}
                      onChange={(e) => onChangeValue('policy', e.target.checked)}
                    />
                    <span>
                      By checking this button, you agree to our
                      <a href="#!" onClick={() => setIsVisible(true)} class="privacy-policy-text">
                        Terms of service
                      </a>{' '}
                      and acknowledge that you have read our
                      <a href="#!" onClick={() => setIsVisible(true)} class="privacy-policy-text">
                        Privacy Policy
                      </a>{' '}
                      to learn how we collect, use, and share your data.
                    </span>
                  </label>
                  <div style={{ marginTop: 20 }}>
                    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={onChangeRecaptcha} />
                  </div>
                  <a
                    className="text-center btn_booking btn-black w-full text-center items-center justify-center"
                    onClick={() => handleSubmitOrder(2)}
                  >
                    Request to book
                  </a>
                  <a onClick={() => onChangePage(0, true)} className="cancel-book-text">
                    Cancel Request
                  </a>
                </div>
              </div>
            </div>
          </form>
        </div>

        {isVisible && (
          <Modal className="custom-modal wrap_modal_policy" open={isVisible} footer={true} centered onCancel={() => setIsVisible(false)}>
            <div className="modal_content relative bg-ci-guspace shadow">
              <div className="modal_header flex items-center justify-between">
                <h3 className="font-bold font-noto">PRIVACY POLICY</h3>
                <button type="button" className="inline-flex items-center">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setIsVisible(false)}>
                    <path d="M2 2L22.4043 22.4044" stroke="black" stroke-width="4"></path>
                    <path d="M22.4043 2L1.99991 22.4043" stroke="black" stroke-width="4"></path>
                  </svg>
                </button>
              </div>
              <hr />
              <iframe  title="policy" src={masterData && masterData.privacy_policy_link+'#toolbar=0'} style={{ width: '100%', height: '70vh' }}></iframe>
            </div>
          </Modal>
        )}
      </main>
    </div>
  )
}
