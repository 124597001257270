import React from 'react'
import { useParams } from 'react-router-dom'

import DateRangPicker from 'components/global/dateRangPicker'
import DatePicker from '../../global/datePicker'
import addDayIcon from 'assets/images/extened_day.png'
import {
  FacebookShareButton,
  LineShareButton,
  TwitterShareButton,  
} from "react-share";
export default function Index({
  freeSlots = [],
  selectdRange = null,
  onSelectTimeRange,
  onSelectDate,
  onAddedExtended,
  onChangePage,
  onDeleteExtended,
  sumOrderPrice,
  spaceName,
  sumOrderHours,
  sumOrderProcessPrice,
  findDuplicateSelected,
  space = {}
}) {
  const shareIg = (shareUrl) => {
    const url = 'https://www.instagram.com/sharer.php?=' + encodeURIComponent(shareUrl);
    window.open(url, '_blank', 'width=600,height=600');
  }
  return (
    <section className="py-gu-100 relative" style={{background: "url(assets/images/bg_brick04.svg) center no-repeat", backgroundSize:"contain !important"}}>
      {/* <div className="bg_brick02"></div> */}
      <div className="container_gu">
        <div className="txthead text-center">
          <h2>{space.space_detail_options}</h2>
          {/*<p style={{textTransform:'capitalize'}} className='text-xl'>{spaceName}</p>*/}
          <p style={{textTransform:'capitalize'}} className='text-xl' >{space.location}</p>
        </div>

        <div className="py-gu-30">
          <div className="filter_date">
            {freeSlots.length > 0 &&
              freeSlots.map((freeSlot) => {
                return (
                  <div style={{ marginBottom: 10 }}>
                    <div className="gu_date">
                      <DatePicker
                        onSelect={(e) => onSelectDate(e, freeSlot.index)}
                        placeholder="Select date"
                        defaultValue={freeSlot.selected_date}
                        value={freeSlot.selected_date}
                      />
                    </div>
                    <div className="gu_time">
                      <DateRangPicker
                        onSelect={(e) => onSelectTimeRange(e, freeSlot.index)}
                        options={
                          freeSlot && freeSlot.free_slots
                            ? freeSlot.free_slots.map((slot) => {
                                let isDuplicate = findDuplicateSelected(freeSlots, freeSlot, slot)

                                return {
                                  value: {
                                    start: slot.start,
                                    end: slot.end,
                                    price_process: parseInt(slot.price_process),
                                  },
                                  name: `${slot.part} (${slot.start} - ${slot.end})`,
                                  disabled: isDuplicate ? isDuplicate : !slot.status,
                                }
                              })
                            : [
                                {
                                  value: 'none',
                                  name: `Something went wrong.`,
                                  disabled: true,
                                },
                              ]
                        }
                        placeholder="Select Hour"
                        value={freeSlot.selected_range}
                      />
                    </div>
                  </div>
                )
              })}
            <div className="added-day-container">
              {freeSlots.length < 7 ? (
                <div className="added-day" onClick={onAddedExtended}>
                  <img src="/assets/images/icon_extendaday.svg" alt="added_day" /> <span>Extend a day</span>
                </div>
              ) : (
                <div></div>
              )}
              {freeSlots.length > 1 && (
                <div className="delete-day" onClick={onDeleteExtended}>
                  Remove
                </div>
              )}
            </div>
            {selectdRange ? (
              <div className="gu_result">
                <h2>Price</h2>
                <div className="details">
                  <div className="list">
                    <p>{sumOrderHours} hours</p>
                    <p>{new Intl.NumberFormat().format(sumOrderPrice)} บาท</p>
                  </div>
                  <div className="list">
                    <p>Discount</p>
                    <p>{new Intl.NumberFormat().format(sumOrderProcessPrice)} บาท</p>
                  </div>
                </div>
                <div className="total">
                  <p>Total</p>
                  <p>{new Intl.NumberFormat().format(sumOrderPrice + sumOrderProcessPrice)} บาท</p>
                </div>
              </div>
            ) : (
              <></>
            )}
            <a href="#!" className="btn_request btn-yellow w-full text-center items-center justify-center mt-4" onClick={() => onChangePage(1)}>
              Request to book
            </a>
          </div>
        </div>

        <div className="share_list justify-center">
            
          <div className="flex flex-col justify-center items-center "> 
            <h2>share</h2>
            <div className="mt-4">
            <FacebookShareButton className="ml-2" url={window.location.href} >
              <img src="/assets/images/social/fb_bw.svg" alt="" aria-label="facebook" />
            </FacebookShareButton>
            <TwitterShareButton className="ml-2" url={window.location.href} >
              <img src="/assets/images/social/tw_bw.svg" alt="" aria-label="twitter" />
            </TwitterShareButton>
            <LineShareButton  className="ml-2" url={window.location.href} >
              <img src="/assets/images/social/line_bw.svg" alt="" aria-label="line" />
            </LineShareButton>
            {/* <div onClick={()=>{shareIg(window.location.href)}}>
            <img src="/assets/images/social/ig_bw.svg" alt="" aria-label="instagram" />
            </div>
              */}
            </div>
 
          </div>
        </div>
      </div>
    </section>
  )
}
