import { useEffect, useState, useContext } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { order } from 'utils/services/api/schemas'
import { validEmail } from 'utils/helpers'
import { Loading } from 'utils/services/store'

import CommonServives from 'utils/services/api/common'
import moment from 'moment/moment'
export default function BookingContentFunction(onChangePage, setResultStructure, freeSlots) {
  const { id } = useParams()
  const { create } = CommonServives()
  const navigate = useNavigate()

  const { setIsLoading } = useContext(Loading)
  const [lineProfile, setLineProfile] = useState(null)
  const [viaContract, setViaContract] = useState(null)
  const [recaptchaPass, setRecaptchaPass] = useState(null)
  const [isRedirect, setIsRedirect] = useState(false)
  const [orderStructure, setOrderStructure] = useState({
    space_id: id,
    event: null,
    event_other: '',
    email: null,
    phone: null,
    policy: null,
    alcohol: null,
    uid_line: null,
    full_name: null,
    display_name: null,
    booking_date: null,
    channel_contact: null,
    number_of_people: null,
    total_price: null,
    promo_code: '',
    note: '',
  })

  useEffect(() => {
    const isLineRedirect = window.location.search && window.location.search.split('currentPage=').length > 1
    const orderDetail = JSON.parse(localStorage.getItem('book_detail'))
    const line_profile = JSON.parse(localStorage.getItem('line_profile'))

    setIsRedirect(isLineRedirect)

    if (orderDetail && isLineRedirect) {
      if (line_profile) {
        setLineProfile(line_profile)
        orderDetail.display_name = line_profile.displayName
        orderDetail.uid_line = line_profile.userId
      }

      setOrderStructure(orderDetail)
    }
  }, [])

  useEffect(() => {
    window.addEventListener('message', receiveMessage)
    if (lineProfile) {
      onChangeValue([
        { key: 'display_name', value: lineProfile.displayName },
        { key: 'uid_line', value: lineProfile.userId },
      ])
    }
  }, [lineProfile])

  function handleSubmitOrder(page) {
    let isError = false
    let isEmailError = !validEmail.test(orderStructure['email'])
    let isLoginWithLine = orderStructure['channel_contact'] === 'line'
    let noted = []

    Object.keys(orderStructure).forEach((key) => {
      if (orderStructure[key] === null || (key === 'policy' && orderStructure[key] === false)) {
        if (isLoginWithLine) {
          isError = true
        } else {
          if (key !== 'uid_line' && key !== 'display_name') {
            isError = true
          }
        }
      }
    })
    noted.push(orderStructure.note)
     
    orderStructure.booking_date.forEach((item) => {
      noted.push(item.note)
      delete item.note
    })
    orderStructure.note = noted.join(', ')

    if(orderStructure.event === 'Other'){
      orderStructure.event = orderStructure.event_other
    } 
    
    console.log(orderStructure)
    if (!isError && !isEmailError && recaptchaPass) {
      setIsLoading(true)
      create(order, orderStructure)
        .then((res) => {
          if (res.code !== 201) {
            if (res.errors) {
              alert(`เกิดข้อผิดพลาดในการทำงาน : ${res.errors.map((error) => `${error.msg}: ${error.param}`).join(', ')}`)
            } else {
              alert(`เกิดข้อผิดพลาดในการทำงาน : ${res.message}`)
            }
            setIsLoading(false)
          } else {
            setTimeout(() => {
              setIsLoading(false)
            }, 1500)
            onChangePage(page)
            setResultStructure(orderStructure)
          }
        })
        .catch((err) => {
          setIsLoading(false)
          alert(err)
        })
    } else {
      if (isEmailError) {
        alert(`กรุณากรอก 'อีเมล์' ให้ถูกต้อง  !`)
      } else if (!recaptchaPass) {
        alert(`กรุณากดยอมรับ reCAPTCHA !`)
      } else {
        alert(`กรุณากรอกข้อมูลให้ครบถ้วน !`)
      }
    }
  }

  function onChangeRecaptcha(value) {
    setRecaptchaPass(value)
  }

  function updateBooking(slots = [], sumPrice) {
    let booking_date = []

    slots.forEach((slot) => {
      const isNextDayOfTime = moment(slot.selected_range.value.start, 'HH:mm') > moment(slot.selected_range.value.end, 'HH:mm')
      const datetime_start = `${moment(slot.selected_date).format('YYYY-MM-DD')} ${slot.selected_range ? slot.selected_range.value.start : ''}`
      const datetime_end = `${moment(slot.selected_date)
        .add(isNextDayOfTime ? 1 : 0, 'd')
        .format('YYYY-MM-DD')} ${slot.selected_range ? slot.selected_range.value.end : ''}`

      booking_date.push({
        datetime_start: datetime_start,
        datetime_end: datetime_end,
        note: slot.note,
      })
    })

    onChangeValue([
      { key: 'booking_date', value: booking_date },
      { key: 'total_price', value: sumPrice },
    ])
  }

  function onUnlinkLine() {
    setLineProfile(null)
    window.localStorage.removeItem('line_profile')
    onChangeValue([
      { key: 'display_name', value: null },
      { key: 'uid_line', value: null },
    ])
  }

  function onlinkLine(profileData) {
    setLineProfile(profileData)
  }

  function onChangeValue(object, value = null) {
    let structure = { ...orderStructure }

    if (typeof object === 'string') {
      structure[object] = value
    } else {
      object.forEach((obj) => {
        structure[obj.key] = obj.value
      })
    }

    setOrderStructure(structure)
  }

  function onSelectContract(contract) {
    if (contract.value === 'email') {
      setLineProfile(null)
      onChangeValue([
        { key: 'display_name', value: '' },
        { key: 'uid_line', value: '' },
      ])
    }
    onChangeValue('channel_contact', contract.value)
    setViaContract(contract.value)
  }

  function loginLine() {
    const isMobile = /Mobile|Android|iP(hone|ad)/.test(navigator.userAgent)

    const width = 500
    const height = 800
    const left = window.screen.width / 2 - width / 2
    const top = window.screen.height / 2 - height / 2
    const options = `width=${width},height=${height},left=${left},top=${top}`
    const url = `https://liff.line.me/${process.env.REACT_APP_LINE_LIFF_ID}`

    localStorage.setItem('book_data', JSON.stringify(freeSlots))
    localStorage.setItem('book_detail', JSON.stringify(orderStructure))
    localStorage.setItem('book_id', id)

    setIsLoading(true)

    setTimeout(() => {
      if (!isMobile) {
        if (window.innerWidth <= 1024) {
          navigate('/line')
        } else {
          window.open(url, '_blank', options)
        }
        // navigate('/line')
      } else {
        // navigate('/line')
        navigate('/line')
      }

      setIsLoading(false)
    }, 1000)
  }

  function receiveMessage(event) {
    let profile = event.data
    if (profile.userId) {
      onlinkLine(profile)
    }
  }

  return {
    orderStructure,
    isRedirect,
    lineProfile,
    viaContract,
    onChangeRecaptcha,
    onUnlinkLine,
    loginLine,
    onChangeValue,
    onSelectContract,
    updateBooking,
    handleSubmitOrder,
  }
}
