import React, { useState, useEffect, useRef } from 'react'
 

export default function Socials () {
  return (
    <div className="social">
             <a target='_blank' href="https://www.facebook.com/guspacebkk">
              <img src="/assets/images/social/fb_bw.svg" alt="" aria-label="facebook" />
            </a>
            <a target='_blank' href="https://www.tiktok.com/@guspacebkk">
              <img src="/assets/images/social/tiktok.svg" alt="" aria-label="tiktok" />
            </a>
            <a href="https://www.instagram.com/guspace_bkk" target="_blank">
              <img src="/assets/images/social/ig_bw.svg" alt="" aria-label="ig" />
            </a>
            </div>
  )
}   